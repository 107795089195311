<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";

export default {
  components: { ProDocumentBreadcrumbs },
  name: "ApprovalList",
  data() {
    return {
      isLoading: false,
      processTypes: [],
      dateFormat: "Y-m-d",
      processMap: {},
      isDialogFullScreen: false,
      doShowForm: false,
      dialogTitle: "",
    };
  },
  mounted() {
    this.getTabsList();
  },
  computed: {
    language() {
      return localStorage.getItem("Language");
    },
    fieldPrefix() {
      return this.language[0]?.toLowerCase() || "e";
    },
  },
  methods: {
    dateFormatter(row, column) {
      return this.$proSmart.common.getFormattedDate(row[column.property]);
    },
    getTabsList() {
      this.isLoading = true;
      this._getTabType()
        .then((types_) => {
          let types = types_.ReturnData.$.toJson();
          if (types.length < 1) {
            this.isLoading = true;
          } else {
            switch (this.language) {
              case "Chinese":
                types.map((item) => {
                  item.processtypename = item.cndescription;
                });
                break;
              case "English":
                types.map((item) => {
                  item.processtypename = item.endescription;
                });
                break;
              case "TraditionalChinese":
                types.map((item) => {
                  item.processtypename = item.tndescription;
                });
                break;
            }
            this.processTypes = types;
          }

          types.forEach((t) => {
            const t_ = t.processtype;
            if (!this.processMap[t_]) {
              this.$set(this.processMap, t_, {
                data: [],
                isLoading: false,
                fields: [],
              });
            }

            this.$set(this.processMap[t_], "fields", []);

            this.processMap[t_].isLoading = true;

            this._getPendingListByType(t_)
              .then(async ({ data, fields }) => {
                if (data.length > 0) {
                  const tenderRefList = await this.$proSmart.procurerUi.getProcessIdToTenderRef(
                    this,
                    JSON.stringify(data.map((b) => b.processid))
                  );

                  let processIdMapping = tenderRefList.reduce(
                    (a, v) => ({ ...a, [v.processId]: v }),
                    {}
                  );

                  data.forEach((b) => {
                    let mappingElement = processIdMapping[b.processid];

                    if (mappingElement) {
                      b.tenderRef = mappingElement.tenderRef;
                      b.tenderSubject = mappingElement.tenderSubject;
                      b.tenderId = mappingElement.tenderId;
                    }
                  });
                }

                this.processMap[t_].data = data;
                this.processMap[t_].fields = fields;
                this.processMap[t_].isLoading = false;
              })
              .catch(() => {
                this.processMap[t_].isLoading = false;
              });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    _getTabType() {
      return new Promise((resolve, reject) => {
        this.invokeService(
          "Workflow",
          "GetMyApproveProcessTypes",
          [this.LocalUser.LoginUserId],
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    /**
     * @param type
     * @returns {Promise<{data: Array<{processid: string}>}>}
     * @private
     */
    _getPendingListByType(type) {
      return new Promise((resolve, reject) => {
        this.invokeService(
          "Workflow",
          "GetMyWaitingActivityWithBizData",
          [{ processtype: type, limit: 999, page: 1 }],
          (resp) => {
            const fields =
              resp.ReturnData?.$.attributes?.ColumnList?.toJson() || [];
            resolve({
              data: this._formatData(resp.ReturnData?.$, fields),
              fields: fields,
            });
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    _formatData(data, fields) {
      if (!data) return [];

      const result = data.toJson();
      if (!result) return [];

      result.forEach((row) => {
        fields.forEach((f) => {
          const fieldName = this.fieldPrefix + "_fieldname";
          this.$set(
            row,
            f.uid.toString(),
            f[fieldName].replace(/{{paras\.([^}]+)}}/g, (_, fn) =>
              this._getFieldValue(fn, row, data.columns)
            )
          );
        });
      });

      return result;
    },
    _getFieldValue(field, row, columns) {
      const col = columns.find((c) => c.Name === "biz_" + field);
      if (col) {
        //TODO: Format value by type
        if (col.DataType === "Datetime") {
          row[col.Name] = this.$proSmart.common.getFormattedDate(row[col.Name]);
        }
        // } else if (col.DataType == 'Int') {
        // } else if (col.DataType == 'Decimal') {
        // }

        return row[col.Name]?.toString() || "";
      } else {
        return row[field];
      }
    },
    _renderTable(type) {
      return (
        <el-table
          data={this.processMap[type.processtype].data.filter(
            (f) => f.tenderId !== undefined
          )}
          class="hcm-todo-list-table"
          border
          ref={"dt_" + type.processtype}
          v-loading={this.processMap[type.processtype].isLoading}
          onSelectionChange={this._handleSelectionChange}
          header-align="center"
          show-overflow-tooltip="true"
          element-loading-background="rgba(255,255,255,0.5)"
        >
          <el-table-column
            width="70"
            scopedSlots={{
              default: ({ row }) => (
                <el-button
                  type="text"
                  size="small"
                  onClick={() => this._handleApprovalShow(row, false)}
                >
                  {this.getRes("myapplication.MyApplication.Detail")}
                </el-button>
              ),
            }}
          ></el-table-column>
          {this.processMap[type.processtype].fields
            .filter((f) => f.sequence < 0)
            .sort((a, b) => a - b)
            .map((f) => (
              <el-table-column
                min-width="12"
                prop={f.uid.toString()}
                label={f[this.fieldPrefix + "_name"]}
              ></el-table-column>
            ))}
          <el-table-column
            width="240"
            prop="tenderRef"
            label={this.$t("Document.Ref")}
          ></el-table-column>
          <el-table-column
            min-width="400"
            prop="tenderSubject"
            label={this.$t("Document.Subject")}
          ></el-table-column>
          <el-table-column
            width="240"
            prop="processownerusername"
            label={this.getRes("CustMenu.ApprovalList.SubmittedBy")}
          ></el-table-column>
          <el-table-column
            width="240"
            prop="createtime"
            formatter={this.dateFormatter}
            label={this.getRes("CustMenu.ApprovalList.SubmittedOn")}
          ></el-table-column>
          {this.processMap[type.processtype].fields
            .filter((f) => f.sequence >= 0)
            .sort((a, b) => a - b)
            .map((f) => (
              <el-table-column
                min-width="12"
                prop={f.uid.toString()}
                label={f[this.fieldPrefix + "_name"]}
              ></el-table-column>
            ))}
        </el-table>
      );
    },
    _handleSelectionChange() {},
    _handleApprovalClose() {
      this.doShowForm = false;
      this.getTabsList();
    },
    _handleCancel() {
      this.isRenderForm = false;
      this.loading && this.loading.close();
    },
    _handleSuccess(lcID) {
      const loList = lcID.split(",") || [];

      for (var i = 0; i < loList.length; i++) {
        this.$emit("RefreshInbox", loList[i]);
      }

      this._handleApprovalClose();
    },
    _handleApprovalShow(item, bShowActionBtn, showKey = "doShowForm") {
      const activityId = item.activityid;

      // this.dialogTitle = this.getRes("MyApprovalList.Header") + " - " + item.processname;
      this.dialogTitle = item.processname;
      this.ProcessInfo.clear();
      this.ProcessInfo.set("showActionBtn", bShowActionBtn);
      this.ProcessInfo.set("processId", item.processid);
      this.ProcessInfo.set("processdescription", item.processdescription);
      this.ProcessInfo.set("processModelId", item.processmodelid);
      this.ProcessInfo.set("processName", item.processname);
      this.ProcessInfo.set("activityId", item.activityid);
      this.ProcessInfo.set("recipientId", item.paridorcid);
      this.ProcessInfo.set("cancelId", item.paridorcid);
      this.ProcessInfo.set("modelActivityId", item.modelactivityid);

      if (this.OldTypeResult === "COMPLETED") {
        if (activityId !== "") {
          this.ProcessInfo.set("action", "ViewActivity");
        } else {
          this.ProcessInfo.set("action", "ViewCancel");
        }
      } else {
        if (activityId !== "") {
          this.ProcessInfo.set("action", "DealActivity");
        } else {
          this.ProcessInfo.set("action", "DealCancel");
        }
      }
      this[showKey] = true;
    },
    _renderForm() {
      return (
        <el-dialog
          fullscreen={this.isDialogFullScreen}
          visible={this.doShowForm}
          beforeClose={this._handleApprovalClose}
          closeOnClickModal={false}
          appendToBody={true}
          customClass="hcm-todo-form"
          showClose={false}
          on={{
            "update:visible": (val) => (this.doShowForm = val),
            open: () => (this.isDialogFullScreen = false),
          }}
          scopedSlots={{
            default: () => (
              <div class="hcm-todo-form-body">
                {this.doShowForm ? (
                  <DoApprove
                    isBig={this.isDialogFullScreen}
                    ref="DoApproveForm"
                    style="min-height: calc(60vh)"
                    on={{
                      ActionSuccess: this._handleSuccess,
                      handleCancel: this._handleCancel,
                      setFullScreen: (val) => (this.isDialogFullScreen = val),
                    }}
                  ></DoApprove>
                ) : (
                  ""
                )}
              </div>
            ),
            title: () => (
              <div class="hcm-todo-form-header">
                <span class="hcm-todo-form-title">{this.dialogTitle}</span>
                <div class="hcm-todo-form-tool-bar">
                  <i
                    onClick={() =>
                      (this.isDialogFullScreen = !this.isDialogFullScreen)
                    }
                    class={{
                      "hcm-todo-form-tool-bar-item": true,
                      fa: true,
                      "fa-window-maximize": !this.isDialogFullScreen,
                      "fa-window-restore": this.isDialogFullScreen,
                    }}
                  ></i>
                  <i
                    class="fa fa-close hcm-todo-form-tool-bar-item"
                    onClick={() => (this.doShowForm = !this.doShowForm)}
                  ></i>
                </div>
              </div>
            ),
          }}
        ></el-dialog>
      );
    },
  },
  render() {
    return (
      <div class="hcm-todo-list">
        <el-container>
          <el-header class="hcm-todo-list-header">
            <pro-document-breadcrumbs
              breadcrumbs={[
                {
                  name: "menu.MyApprovalList",
                  icon: "PROSmart-Approval",
                },
              ]}
              user-role="procurer"
            ></pro-document-breadcrumbs>
          </el-header>
          <el-main class="hcm-todo-list-body">
            {this.processTypes
              .filter(
                (process) =>
                  this.processMap[process.processtype].data.filter(
                    (f) => f.tenderId !== undefined
                  ).length > 0
              )
              .map((type) => [
                <div class="hcm-todo-list-category" style="font-size: 16px;">
                  {type.processtypename}
                </div>,
                <div>{this._renderTable(type)}</div>,
              ])}
          </el-main>
        </el-container>
        {this._renderForm()}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.hcm-todo-list {
  overflow-x: auto;
  max-height: calc(100vh - 85px) !important;

  &-header {
    height: 30px;
  }

  &-body {
    overflow: auto !important;
    max-height: calc(100vh - 130px) !important;
  }

  &-table {
    width: 100%;
    overflow: auto;
  }
}

.hcm-todo-form {
  &-header {
    display: flex;
    color: #fff;
    align-items: center;
  }

  &-title {
    flex: auto;
    font-weight: 700;
    font-size: 18px;
  }

  &-tool-bar {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    &-item {
      font-size: 18px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.el-dialog__wrapper .el-dialog.hcm-todo-form {
  &.is-fullscreen {
    margin: 0 !important;
    max-width: unset;
  }

  .hcm-todo-form-title {
    font-weight: normal;
  }
}
</style>
